<template>
  <div>
    <base-svg-icons />
    <!-- The Header -->
    <FixedHeader :threshold="70">
      <header role="banner" v-if="user.type == 0">
        <div class="container">
          <div class="flex-row">
            <div
              class="left-side"
              :class="user.type != 0 ? 'onResponsive' : ''"
            >
              <!--Mobile menu-icon -->
              <a href="javascript:void(0)" class="menuIcon d-none show-on-lg">
                <base-svg-icon
                  class="menu"
                  icon-id="menuIcon"
                  icon-viewbox="0 0 44 44"
                >
                </base-svg-icon>
                <base-svg-icon
                  class="arrow"
                  icon-id="arrowIcon"
                  icon-viewbox="0 0 38 38"
                >
                </base-svg-icon>
              </a>
              <!-- logo -->
              <router-link to="/user" class="logo">
                <img
                  src="@/assets/images/svg/logo.svg"
                  class="img-fluid"
                  alt=" logo"
                />
              </router-link>
              <nav>
                <ul>
                  <li>
                    <router-link to="/user">Dashboard</router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/user/offers">Free offers</router-link>
                  </li> -->
                  <li>
                    <router-link to="/user/discover">Discover</router-link>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="right-side">
              <ul class="list-unstyled">
                <li class="notifaction_Wrapper hide-on-lg">
                  <router-link to="/user/notifications" class="notification">
                    <base-svg-icon
                      icon-id="notiffication_icon"
                      icon-viewbox="0 0 20 22"
                    >
                    </base-svg-icon>
                    <span></span>
                  </router-link>
                </li>
                <li class="amount hide-on-lg">
                  <router-link to="/user/earning" class="btn btn-primary">
                    {{ balance.currency }} {{ balance.amount }}
                    <base-svg-icon
                      icon-id="amount"
                      icon-viewbox="0 0 21.302 19.05"
                    >
                    </base-svg-icon>
                  </router-link>
                </li>
                <!-- Dropdown -->
                <li class="hide-on-lg account-dropdown">
                  <account-dropdown></account-dropdown>
                </li>
              </ul>
            </div>
            <!-- Setting Icon on Responsive -->
            <div class="settingIcon d-none show-on-lg">
              <account-dropdown :mobile="true"></account-dropdown>
            </div>
            <!-- Menu on mobile -->
            <nav class="d-none show-on-lg">
              <div>
                <!-- Mobile Main links -->
                <ul v-if="user.type == 0">
                  <li>
                    <router-link to="/user">Dashboard</router-link>
                  </li>
                  <li>
                    <router-link to="/user/offers">Free offers</router-link>
                  </li>
                  <li>
                    <router-link to="/user/discover">Discover</router-link>
                  </li>
                </ul>
                <!-- Mobile Notification -->
                <router-link to="/user/notifications" class="notification">
                  Notifications
                  <span>4</span>
                </router-link>
                <ul class="align-items-center">
                  <li class="amount on-responsive">
                    <router-link to="/user/earning" class="btn btn-primary">
                      {{ balance.currency }} {{ balance.amount }}
                      <base-svg-icon
                        icon-id="amount"
                        icon-viewbox="0 0 21.302 19.05"
                      >
                      </base-svg-icon>
                    </router-link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <header role="banner" v-else>
        <div class="container">
          <div class="flex-row">
            <div
              class="left-side"
              :class="user.type != 0 ? 'onResponsive' : ''"
            >
              <!--Mobile menu-icon -->
              <a href="javascript:void(0)" class="menuIcon d-none show-on-lg">
                <base-svg-icon
                  class="menu"
                  icon-id="menuIcon"
                  icon-viewbox="0 0 44 44"
                >
                </base-svg-icon>
                <base-svg-icon
                  class="arrow"
                  icon-id="arrowIcon"
                  icon-viewbox="0 0 38 38"
                >
                </base-svg-icon>
              </a>
              <!-- logo -->
              <router-link to="/brand/" class="logo">
                <img
                  src="@/assets/images/svg/logo.svg"
                  class="img-fluid"
                  alt=" logo"
                />
              </router-link>
            </div>
            <div class="site-search hide-on-lg">
              <!-- search bar -->
              <form class="search no-label">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Everything"
                />
              </form>
            </div>
            <div class="right-side">
              <ul class="list-unstyled">
                <!-- New compaign & Pop up open Link -->
                <li class="newCompaignBtn hide-on-lg">
                  <router-link
                    to="/brand/new-campaign"
                    v-b-modal.introduction
                    class="newCompaign"
                    :disabled="user.state == 0"
                  >
                    New Campaign
                  </router-link>
                </li>
                <!-- Notification -->
                <li class="notifaction_Wrapper hide-on-lg">
                  <router-link to="/brand/notifications" class="notification">
                    <base-svg-icon
                      icon-id="notiffication_icon"
                      icon-viewbox="0 0 20 22"
                    >
                    </base-svg-icon>
                    <span></span>
                  </router-link>
                </li>
                <li class="amount hide-on-lg">
                  <router-link
                    to="/brand/billing"
                    class="btn btn-primary"
                    :disabled="user.state == 0"
                  >
                    {{ balance.currency }} {{ balance.amount }}
                    <base-svg-icon
                      icon-id="amount"
                      icon-viewbox="0 0 21.302 19.05"
                    >
                    </base-svg-icon>
                  </router-link>
                </li>
                <!-- Dropdown -->
                <li class="hide-on-lg">
                  <account-dropdown :user="user"></account-dropdown>
                </li>
              </ul>
            </div>
            <!-- Setting Icon on Responsive -->
            <div class="settingIcon d-none show-on-lg">
              <account-dropdown :mobile="true"></account-dropdown>
            </div>
            <!-- Menu on mobile -->
            <nav class="d-none show-on-lg">
              <div>
                <!-- Mobile Search -->
                <div class="search" v-if="user.type != 0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Everything"
                  />
                </div>
                <!-- New compaign & Pop up open Link -->
                <div class="newCompaignBtn" v-if="user.type != 0">
                  <router-link
                    to="/brand/new-campaign"
                    v-b-modal.introduction
                    class="newCompaign"
                  >
                    New Campaign
                  </router-link>
                </div>
                <!-- Mobile Main links -->
                <ul class="mainLinks">
                  <li>
                    <router-link to="/brand">
                      <base-svg-icon
                        icon-id="compaign"
                        icon-viewbox="0 0 14.788 13.238"
                      >
                      </base-svg-icon>
                      Campaign
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/brand/influencers">
                      <base-svg-icon
                        icon-id="Influencers"
                        icon-viewbox="0 0 20.738 11.668"
                      >
                      </base-svg-icon>
                      Influencers
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/brand/deals">
                      <base-svg-icon
                        icon-id="deal"
                        icon-viewbox=" 0 0 14.788 13.238"
                      >
                      </base-svg-icon>
                      Deal
                    </router-link>
                  </li>
                </ul>
                <!-- Mobile Notification -->
                <router-link to="/brand/notifications" class="notification">
                  Notifications
                  <span>4</span>
                </router-link>
                <ul class="align-items-center">
                  <li class="amount on-responsive">
                    <router-link to="/" class="btn btn-primary">
                      {{ balance.currency }} {{ balance.amount }}
                      <base-svg-icon
                        icon-id="amount"
                        icon-viewbox="0 0 21.302 19.05"
                      >
                      </base-svg-icon>
                    </router-link>
                  </li>
                </ul>
                <!-- <a href="#" class="notification">
									Notifications
									<span>4</span>
								</a> -->
              </div>
            </nav>
          </div>
        </div>
      </header>
    </FixedHeader>
    <!-- The Header -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FixedHeader from "vue-fixed-header";
import AccountDropdown from "./partials/AccountDropdown.vue";
export default {
  components: {
    FixedHeader,
    AccountDropdown,
  },

  methods: {
    menuIcon: function () {
      let item = document.querySelector(".menuIcon");
      if (item) {
        item.addEventListener("click", () => {
          document.body.classList.toggle("menu-active");
          document
            .getElementsByTagName("html")[0]
            .classList.toggle("no-scroll");
        });
      }
    },
  },
  computed: {
    ...mapGetters(["user", "balance"]),
  },
  mounted() {
    this.menuIcon();
  },
  watch: {
    $route() {
      // clear alert on location change
      document.body.classList.remove("menu-active");
      document.getElementsByTagName("html")[0].classList.remove("no-scroll");
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background: #fff;
  display: flex;
  align-items: center;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
  margin-bottom: rem(20px);
  position: fixed;
  width: 100%;
  z-index: 9;
  transition: 0.4s ease-in-out;
  .flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
    .left-side {
      display: flex;
      align-items: center;
      &.onResponsive {
        flex: 0 0 200px;
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          flex: 0 0 160px;
        }
      }
      .menuIcon {
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
        left: 0;
        svg {
          width: 35px;
          &.arrow {
            display: none;
          }
        }
      }
      .logo {
        transition: 0.5s ease all;
        p {
          display: none;
          font-size: rem(24px);
          font-weight: 700;
          color: var(--textPrimary);
          margin: 0;
        }
        img {
          max-height: 55px;
        }
        @media screen and (max-width: 991px) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          img {
            max-width: 160px;
          }
        }
      }
    }
    .site-search {
      flex: 0 0 470px;
      padding: 0 rem(15px);
      @media screen and (max-width: 1300px) {
        flex: 0 0 400px;
      }
      @media screen and (max-width: 1199px) {
        flex: 0 0 275px;
      }
      @media screen and (max-width: 991px) {
        display: none;
      }
      form {
        input {
          background: #f9f9fb;
          padding: rem(12px) rem(24px);
          padding-left: 55px;
          border-radius: 6px;
          background-image: url("data:image/svg+xml,%0A%3Csvg id='search_icon' data-name='search icon' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath id='Path_167' data-name='Path 167' d='M12.864,11.321h-.813l-.288-.278a6.7,6.7,0,1,0-.72.72l.278.288v.813L16.467,18,18,16.467Zm-6.175,0A4.631,4.631,0,1,1,11.321,6.69,4.625,4.625,0,0,1,6.69,11.321Z' fill='%23d0d0dd'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: 16px center;
          height: 52px;
          max-height: 52px;
          border: 2px solid transparent;
          color: var(--secondary);
          @media screen and (max-width: 1199px) {
            height: 52px;
            max-height: 52px;
            padding: rem(12px) rem(15px);
            padding-left: 45px;
          }
          &::placeholder {
            color: var(--secondary);
          }
        }
      }
    }
    .right-side {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      ul {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        .newCompaignBtn {
          margin-right: rem(24px);
        }
        li {
          &.notifaction_Wrapper {
            position: relative;
            // &:after {
            // 	content: '';
            // 	position: absolute;
            // 	height: rem(65px);
            // 	width: 1px;
            // 	background: #eff4f6;
            // 	right: 0;
            // 	top: 50%;
            // 	transform: translateY(-50%);
            // }
            // margin-right: rem(15px) !important;
            // padding-right: rem(20px);
          }
          .notification {
            position: relative;
            span {
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background: #5357f7;
              right: 0;
            }
            svg {
              width: 20px;
              color: #c9ced6;
            }
            @media screen and (min-width: 1025px) {
              &:hover {
                svg {
                  color: var(--primary);
                }
              }
            }
          }
          .newCompaign {
            background-image: url("data:image/svg+xml,%0A%3Csvg id='add_new_icon' data-name='add new icon' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath id='Path_168' data-name='Path 168' d='M16,9.143H9.143V16H6.857V9.143H0V6.857H6.857V0H9.143V6.857H16Z' fill='%239637f1'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: 25px center;
            font-size: rem(14px);
            font-weight: 600;
            color: #9637f1;
            border: 2px solid #ccd4e0;
            border-radius: 8px;
            padding: rem(17px) rem(26px);
            padding-left: rem(55px);
            svg {
              fill: var(--primary);
            }
            @media screen and (max-width: 1300px) {
              padding: rem(14px) rem(20px);
              padding-left: rem(30px);
              background-position: 8px center;
            }
            @media screen and (min-width: 1025px) {
              &:hover {
                border-color: var(--primary);
              }
            }
          }
          &.amount {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              height: rem(65px);
              width: 1px;
              background: #eff4f6;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            margin-right: rem(15px) !important;
            margin-left: rem(25px) !important;
            padding-right: rem(20px);
            a {
              width: auto;
              font-size: rem(14px);
              height: auto;
              font-weight: 900;
              border-radius: 50px;
              min-height: auto;
              @include flex(center, center);
              flex-wrap: nowrap;
              padding: rem(9px) rem(28px);
              padding-top: rem(12px);
              &:hover {
                &::before {
                  border-radius: 50px;
                }
              }
              svg {
                width: 20px;
                margin-left: 8px;
                margin-top: -5px;
              }
            }
            @media screen and (max-width: 1199px) {
              margin-right: rem(10px) !important;
              margin-left: rem(10px) !important;
              padding-right: rem(10px);
              a {
                padding: rem(9px) rem(18px);
              }
            }
          }
        }
      }
    }
  }
  nav {
    ul {
      list-style: none;
      @include flex(center, start);
      margin-left: rem(55px);
      li {
        &:not(:last-child) {
          margin-right: rem(25px);
        }
        a {
          font-size: rem(14px);
          color: var(--textPrimary);
          font-weight: 600;
          @media screen and (min-width: 1025px) {
            &:hover {
              color: var(--primary);
            }
          }
          &.router-link-exact-active {
            color: var(--primary);
            font-weight: 900;
          }
        }
        &.amount {
          width: auto;
          font-size: rem(14px);
          height: auto;
          font-weight: 900;
          border-radius: 50px;
          min-height: auto;
          @include flex(center, center);
          flex-wrap: nowrap;
          padding: rem(9px) rem(28px);
          border: 0;
          padding-top: rem(12px);
          svg {
            width: 20px;
            margin-left: 8px;
            margin-top: -5px;
          }
          &:after {
            display: none;
          }
        }
      }
      @media screen and (max-width: 991px) {
        margin-left: rem(35px);
      }
    }
  }
  @media screen and (max-width: 991px) {
    nav {
      position: fixed;
      left: -200%;
      background: #f3f3f6;
      width: 100%;
      height: 100%;
      top: 65px;
      transition: all 0.5s ease;
      z-index: 999;
      margin-left: 0;
      padding: 0 rem(20px);
      padding-top: rem(20px);
      padding-bottom: 100px;
      overflow-y: auto;
      .search {
        input {
          background-color: #fff;
          padding: rem(12px) rem(24px);
          padding-left: 55px;
          border-radius: 6px;
          background-image: url("data:image/svg+xml,%0A%3Csvg id='search_icon' data-name='search icon' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cpath id='Path_167' data-name='Path 167' d='M12.864,11.321h-.813l-.288-.278a6.7,6.7,0,1,0-.72.72l.278.288v.813L16.467,18,18,16.467Zm-6.175,0A4.631,4.631,0,1,1,11.321,6.69,4.625,4.625,0,0,1,6.69,11.321Z' fill='%23d0d0dd'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: 16px center;
          height: 52px;
          max-height: 52px;
          border: 2px solid transparent;
          color: var(--secondary);
          &::placeholder {
            color: var(--secondary);
          }
        }
      }
      .newCompaignBtn {
        text-align: center;
        margin-top: rem(16px);
        .newCompaign {
          display: inline-block;
          background-image: url("data:image/svg+xml,%0A%3Csvg id='add_new_icon' data-name='add new icon' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath id='Path_168' data-name='Path 168' d='M16,9.143H9.143V16H6.857V9.143H0V6.857H6.857V0H9.143V6.857H16Z' fill='%239637f1'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: 25px center;
          font-size: rem(14px);
          font-weight: 600;
          color: #9637f1;
          border: 2px solid #ccd4e0;
          border-radius: 8px;
          padding: rem(17px) rem(26px);
          padding-left: rem(55px);
          svg {
            fill: var(--primary);
          }
        }
      }
      .notification {
        position: relative;
        display: flex;
        align-items: center;
        color: #253858;
        font-weight: 700;
        font-size: rem(20px);
        padding: 20px 0;
        border-bottom: 1px solid #d8d8d8;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.414' height='14.828' viewBox='0 0 8.414 14.828'%3E%3Cpath id='Path_934' data-name='Path 934' d='M1,1,7,7l6-6' transform='translate(0.414 14.414) rotate(-90)' fill='none' stroke='%239637f1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        span {
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background: #9637f1;
          right: 40px;
          @include flex(center, center);
          color: #fff;
          font-size: 12px;
          font-weight: 700;
        }
        svg {
          width: 20px;
          margin-right: rem(25px);
        }
      }
      ul {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        li {
          position: relative;
          padding: 20px 0;
          width: 100%;
          border-bottom: 1px solid #d8d8d8;
          &:not(:last-child) {
            margin: 0;
          }
          a {
            display: block;
            font-size: rem(20px);
            font-weight: 700;
          }
          &:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.414' height='14.828' viewBox='0 0 8.414 14.828'%3E%3Cpath id='Path_934' data-name='Path 934' d='M1,1,7,7l6-6' transform='translate(0.414 14.414) rotate(-90)' fill='none' stroke='%239637f1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .checkbox {
    padding: 0;
  }
  @media screen and (max-width: 991px) {
    padding-top: rem(40px);
    padding-bottom: rem(40px);
  }
  &.vue-fixed-header--isFixed {
    box-shadow: -1px 6px 13px -6px rgba(0, 0, 0, 0.34);
    -webkit-box-shadow: -1px 6px 13px -6px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: -1px 6px 13px -6px rgba(0, 0, 0, 0.34);
    padding-top: rem(15px);
    padding-bottom: rem(15px);
    @media screen and (max-width: 991px) {
      padding-top: rem(35px);
      padding-bottom: rem(35px);
    }
  }
  .settingIcon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    ::v-deep svg {
      width: 22px;
    }
  }
}
.menu-active {
  header {
    background: #f3f3f6;
    nav {
      left: 0px;
      transition: all 0.5s ease;
    }
    .menuIcon {
      svg {
        &.menu {
          display: none;
        }
        &.arrow {
          display: block !important;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .d-none-lg {
    display: none !important;
  }
  .show-on-lg {
    display: block !important;
  }
}
@media screen and (max-width: 575px) {
  .d-none-sm {
    display: none !important;
  }
  .show-on-sm {
    display: block !important;
  }
}
</style>
